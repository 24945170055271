<template>
  <div>
        <!-- 目录管理 -->
        <!-- 顶部 -->
        <el-row>
            <el-col :span='24'>
                <el-form :inline='true'>
                    <el-form-item label='关键字：'>
                        <el-input v-model='filters.name' placeholder='请输入名称' clearable @clear='handleSearch' @input='() =>(filters.name = filters.name.replace(/\s/g,""))' @keyup.enter.native='handleSearch'></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type='primary' @click='handleSearch'>查询</el-button>
                        <el-button type='primary' @click='handleAdd'>新增</el-button>
                        <el-button type='primary' @click='handleEdit'>编辑</el-button>
                        <el-button type='danger' @click='handleDle'>删除</el-button>
                        <el-button type='primary' @click='back'>返回</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <!-- 列表 -->
        <el-table
        border
        v-loading='listLoading'
        :data="tableData"
        highlight-current-row
        @current-change="selectCurrentRow"
        style="width: 95%">
            <el-table-column prop="SortNo" label="序号" width="60" align='center'></el-table-column>
            <el-table-column prop="Name" label="名称" width="" align='center'></el-table-column>
            <el-table-column prop="CreateTime" label="创建时间" width="" align='center' :formatter="formatCreateTime"></el-table-column>
            <el-table-column prop="CreateUserID" label="创建人" width="" align='center'></el-table-column>
            <el-table-column label='操作' width='' align='center'>
            <template slot-scope='scope'>
                <el-button icon='el-icon-notebook-1' size='mini' type='primary' plain @click='see(scope.row.ID)'>查看</el-button>
            </template> 
            </el-table-column>
        </el-table>
        <!-- 新增/编辑/查看 -->
        <el-dialog
        :title="isAdd == 1 ? '新增': isAdd == 2 ? '编辑' : '查看'"
        :visible.sync="dialogVisible"
        width="50%"
        :close-on-click-modal='false'
        @close='handleClose'>
        <div>
            <el-form :model="form" :rules="rules" ref="formRef" label-width="100px" :disabled='isAdd==3?true:false'>
                <el-form-item label="父级目录：" prop="" v-if='JSON.stringify(this.currentRow) != "{}" && isShow'>
                    <el-input v-model.trim="form.parentCata" disabled></el-input>
                </el-form-item>
                <el-form-item label="排序序号：" prop="sortNo">
                    <el-input v-model.trim="form.sortNo"></el-input>
                </el-form-item>
                <el-form-item label="目录名称：" prop="name">
                    <el-input v-model.trim="form.name"></el-input>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="sumbit" :loading='dialogLoading' v-if='isAdd!=3'>确 定</el-button>
        </span>
        </el-dialog>
        <!-- 分页 -->
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pages.pageIndex"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pages.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pages.dataCount">
        </el-pagination>
  </div>
</template>

<script>
import util from '../../../util/date'
import Qs from 'qs';
import {getTeachingVideoCatalogListPage,addTeachingVideoCatalog,updateTeachingVideoCatalogInfo,deleteTeachingVideoCatalog,getTeachingVideoCatalogInfo} from '@/api/api'
export default {
    data(){
        return {
            isAdd:1,
            dialogVisible:false,
            form:{
                sortNo:'',
                name:'',
                teachingMaterialID:'',
                pCatalogID:0,
                parentCata:'', // 存储父目录名称
            },
            rules:{
                name:[{required:true,message:'请输入目录名称',trigger:'blur'}]
            },
            previousPageUrl:'',
            pages:{
                pageIndex:1,
                pageSize:20,
                dataCount:0
            },
            listLoading:false,
            dialogLoading:false,
            filters:{
                name:''
            },
            currentRow:{},
            isShow:true, // 查看时，父级目录是否展示
            tableData:[],
            teachingMaterialID:'', // 教材id
        }
    },
    methods:{
        // 时间格式化
        formatCreateTime: function(row, column) {
            return !row.CreateTime || row.CreateTime == ""
            ? ""
            : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd hh:mm:ss");
        },
        // 获取列表数据
        getData(){
            var params = {
                teachingMaterialID:this.$route.query.id, // window.atob()对url参数进行解密
                name:this.filters.name
            }
            this.listLoading = true
            getTeachingVideoCatalogListPage(params).then(res => {
                this.listLoading = false
                if(res.data.Success){
                    this.tableData = res.data.Response.Data
                    this.pages.dataCount = res.data.Response.DataCount
                }else{
                    this.$message.error(res.data.Message)
                    this.listLoading = false
                }
            }).catch(() => {
                this.listLoading = false
            })
        },
        // 选择当前行
        selectCurrentRow(row){
            this.currentRow = row
        },
        // 查询
        handleSearch(){
            this.pages.pageIndex = 1
            this.getData()
        },
        // 新增
        handleAdd(){
            this.isAdd = 1
            if(JSON.stringify(this.currentRow) == '{}'){
                this.dialogVisible = true
               return 
            }else{
                if(this.currentRow.PCatalogID != 0){
                    this.$message.warning('不能再新增了')
                }else{
                    this.form.pCatalogID = this.currentRow.ID
                    this.form.parentCata = this.currentRow.Name
                    this.dialogVisible = true
                }               
            }
        },
        // 编辑
        handleEdit(){
            var row = this.currentRow
            if(JSON.stringify(row) == '{}'){
                this.$message.warning('请选择要编辑的一行数据')
            }else{
                this.isAdd = 2
                this.form = {
                    sortNo:row.SortNo,
                    name:row.Name,
                    pCatalogName:row.PCatalogName,
                    pCatalogID:row.PCatalogID,
                    teachingMaterialID:row.TeachingMaterialID
                }
                if(row.PCatalogID == 0){
                    this.isShow = false
                }else{
                    this.isShow = true
                    this.form.parentCata = row.PCatalogName
                }
                this.dialogVisible = true
            }
        },
        // 删除
        handleDle(){
            var row = this.currentRow
            if(JSON.stringify(row) == '{}'){
                this.$message.warning('请选择要删除的一行数据')
            }else{
                this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() =>{
                    var params = {id:row.ID}
                    deleteTeachingVideoCatalog(Qs.stringify(params)).then(res => {
                        if(res.data.Success){
                            this.$message.success(res.data.Message)
                            this.getData()
                        }else{
                            this.$message.error(res.data.Message)
                        }
                    }).catch(() => {})
                }).catch(() => {})
            }
        },
        // 返回
        back(){
            this.$router.push({path:'/VideoCatelogueManage',query:{id:`${this.$route.query.id}`}}) // url加密
        },
        // 查看
        see(id){
            this.isAdd = 3
            var params = {id}
            getTeachingVideoCatalogInfo(params).then(res => {
                if(res.data.Success){
                    var data = res.data.Response
                    this.form = {
                        sortNo:data.SortNo,
                        name:data.Name,                       
                    }
                    if(data.PCatalogID == 0){
                        this.isShow = false
                    }else{
                        this.form.parentCata = data.PCatalogName
                        this.isShow = true
                    }       
                    this.dialogVisible = true            
                }else{
                    this.dialogVisible = false
                }
            }).catch(() => {this.dialogVisible = false})
        },
        // 对话框关闭
        handleClose(){
            this.$refs.formRef.resetFields()
            this.form = {
                sort:'',
                name:''
            }
        },
        // 选择当前行
        selectCurrentRow(row){
            this.currentRow = row
        },
        // 分页
        handleSizeChange(value){
            this.pages.pageSize = value
            this.getData()
        },
        handleCurrentChange(value){
            window.sessionStorage.setItem('pagination',JSON.stringify(value))
            this.pages.pageIndex = value
            this.getData()
        },
        // 表单提交
        sumbit(){
            this.$refs.formRef.validate((valid) =>{
                if(valid){
                    if(this.isAdd == 1){
                        this.dialogLoading = true
                        this.addSubmit()
                    }
                    if(this.isAdd == 2){
                        this.dialogLoading = true
                        this.editSubmit()
                    }
                }
            })
        },
        addSubmit(){
            this.form.teachingMaterialID = Number(this.$route.query.id)
            var params = {...this.form}
            addTeachingVideoCatalog(params).then(res => {
                this.dialogLoading = false
                this.dialogVisible = false
                if(res.data.Success){
                    this.$message.success(res.data.Message)
                    this.getData()
                }else{
                    this.$message.error(res.data.Message)
                    this.dialogLoading = false
                    this.dialogVisible = false
                }
            }).catch(() => {
                this.dialogLoading = false
                this.dialogVisible = false
            })
        },
        editSubmit(){
            var params = {...this.form}
            params.id = this.currentRow.ID
            updateTeachingVideoCatalogInfo(params).then(res => {
                this.dialogLoading = false
                this.dialogVisible = false
                if(res.data.Success){
                    this.$message.success(res.data.Message)
                    this.getData()
                }else{
                    this.$message.error(res.data.Message)
                    this.dialogLoading = false
                    this.dialogVisible = false
                }
            }).catch(() =>{
                this.dialogLoading = false
                this.dialogVisible = false
            })
        }
    },
    beforeRouteEnter(to,from,next){
        if(from.path.indexOf(to.path) == -1){
            window.sessionStorage.removeItem('pagination')
        }   
        // 获取上一个页面的url  -----   该守卫不能访问this，需通过next进行回调
        next(vm =>{
            vm.previousPageUrl = from.path
        })    
    },
    created(){
        this.pages.pageIndex = Number(window.sessionStorage.getItem('pagination')) || 1
        this.getData()
    }
}
</script>

<style lang="stylus" scoped>

</style>