<template>
  <div>
    <div id="main" style="width: 100%;min-height:400px;" v-if="isShow"></div>
  </div>
</template>

<script>
var echarts = require('echarts');
import {getStudentExamineeAggregateList} from '@/api/api'
export default {
  name: "Welcome",
  data() {
    return {
      isShow: false,
    }
  },
  methods: {
    // 是否显示图表
    getIsShowViews() {
      var router = JSON.parse(localStorage.getItem("router"))
      for(var o of router){
        if(o.id == 102){
          var item = o.children
          for(var i of item){
            if(i.id == 202){
              var data = i.children
              for(var n of data){
                if(n.id == 212){
                  this.isShow = true
                  break
                }
              }
              break
            }
          }
          break
        }
      }
    },
    // 页面数据
    getListData(){
      var userData = JSON.parse(localStorage.getItem("user"))
      var params = {
        provinceID: userData.ProvinceID?userData.ProvinceID : null,
        cityID: userData.CityID?userData.CityID : null,
      }
      getStudentExamineeAggregateList(params).then(res => {
        if(res.data.Success){
          var result = res.data.Response
          var bottomData = []
          var LearningNum = []
          var WaitingExamNum = []
          var PendingCertificationNum = []
          var IssuedNum = []
          result.forEach(item => {
            bottomData.push(item.AreaDictionaryName)
            LearningNum.push(item.LearningCount)
            WaitingExamNum.push(item.ExamingCount)
            PendingCertificationNum.push(item.CertificatingCount)
            IssuedNum.push(item.CertificatedCount)
          });
          if(this.isShow){
            this.getViewCharts(bottomData,LearningNum,WaitingExamNum,PendingCertificationNum,IssuedNum);
          }
        }else{
          
        }
      })
    },
    getViewCharts(bottomData,LearningNum,WaitingExamNum,PendingCertificationNum,IssuedNum){
      var myChart = echarts.init(document.getElementById('main'));
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['学习中人数','待考试人数','待发证人数','已发证人数']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: bottomData || ['','','','','','','','','','','','','','','']
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '学习中人数',
            type: 'bar',
            stack: 'viewsPeoNum',
            emphasis: {
              focus: 'series'
            },
            data: LearningNum || ['','','','','','','','','','','','','','','']
          },{
            name: '待考试人数',
            type: 'bar',
            stack: 'viewsPeoNum',
            emphasis: {
              focus: 'series'
            },
            data: WaitingExamNum || ['','','','','','','','','','','','','','','']
          },{
            name: '待发证人数',
            type: 'bar',
            stack: 'viewsPeoNum',
            emphasis: {
              focus: 'series'
            },
            data: PendingCertificationNum || ['','','','','','','','','','','','','','','']
          },{
            name: '已发证人数',
            type: 'bar',
            stack: 'viewsPeoNum',
            emphasis: {
              focus: 'series'
            },
            data: IssuedNum || ['','','','','','','','','','','','','','','']
          }
        ]
      });
    }
  },
  mounted() {
    var curTime = new Date();
    if (window.localStorage.TokenExpire) {
      var expiretime = new Date(Date.parse(window.localStorage.TokenExpire));
      if (curTime >= expiretime) {
        this.$router.push("/login");
        return
      }
    } else {
      this.$router.push("/login");
      return
    }
    // this.getIsShowViews();
    setTimeout(() => {
      if(this.isShow){
        this.getViewCharts();
        this.getListData();
      }
    })
  }
};
</script>
