<template>
  <div>
      <!-- 分类管理 -->
      <!-- 顶部 -->
      <el-row>
        <el-col :span='24'>
          <el-form :inline='true'>
            <el-form-item label='关键字：'>
              <el-input v-model='filters.name' placeholder='请输入名称' clearable @clear='handleSearch' @input='() =>(filters.name = filters.name.replace(/\s/g,""))' @keyup.enter.native='handleSearch'></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type='primary' @click='handleSearch'>查询</el-button>
              <!-- <el-button type='primary' @click='handleAdd'>新增</el-button> -->
              <el-button type='primary' @click='handleEdit'>编辑</el-button>
              <!-- <el-button type='danger' @click='handleDel'>删除</el-button> -->
              <el-button type='primary' @click='back'>返回</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <!-- 列表 -->
      <el-table
      border
      v-loading='listLoading'
      :data="tableData"
      highlight-current-row
      @current-change="selectCurrentRow"
      style="width: 95%">
        <el-table-column  type="index"  width="55" label='序号' ></el-table-column>
        <!-- <el-table-column prop="SortNo" label="序号" width="60" align='center'></el-table-column> -->
        <el-table-column prop="Name" label="名称" width="" align='center'></el-table-column>
        <el-table-column prop="CreateTime" label="创建时间" width="" align='center' :formatter="formatCreateTime"></el-table-column>
        <el-table-column prop="CreateUserID" label="创建人" width="" align='center'></el-table-column>
        <el-table-column label='操作' width='' align='center'>
          <template slot-scope='scope'>
            <el-button icon='el-icon-notebook-1' size='mini' type='primary' plain @click='see(scope.row.ID)'>查看</el-button>
          </template> 
        </el-table-column>
      </el-table>
      <!-- 新增/编辑 -->
      <el-dialog
      :title="isAdd?'新增':'编辑'"
      :visible.sync="dialogVisible"
      :close-on-click-modal='false'
      width="50%"
      @close='handleClose'>
      <div>
        <el-form :model="form" :rules="rules" ref="formRef" label-width="100px">
          <el-form-item label="排序序号：" prop="sortNo">
            <el-input v-model.trim="form.sortNo"></el-input>
          </el-form-item>
          <el-form-item label="名称：" prop="name">
            <el-input v-model.trim="form.name"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit" :loading='dialogLoading'>确 定</el-button>
      </span>
      </el-dialog>
      <!-- 分页 -->
      <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pages.pageIndex"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pages.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pages.dataCount">
      </el-pagination>
      <!-- 查看 -->
      <el-dialog
      title="查看"
      :visible.sync="seeVisible"
      :close-on-click-modal='false'
      width="50%"
      @close='seeHandleClose'>
      <div>
        <el-form :model="form" :rules="rules" ref="formRef" label-width="100px" :disabled='true'>
          <el-form-item label="排序序号：" prop="sortNo">
            <el-input v-model.trim="form.sortNo"></el-input>
          </el-form-item>
          <el-form-item label="名称：" prop="name">
            <el-input v-model.trim="form.name"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="seeVisible = false">取 消</el-button>
      </span>
      </el-dialog>
  </div>
</template>

<script>
import Qs from 'qs';
import util from '../../../util/date'
import {getTeachingVideoCategoryListPage,addTeachingVideoCategory,updateTeachingVideoCategoryInfo,deleteTeachingVideoCategory,GetTeachingVideoCategoryInfo} from '@/api/api'
export default {
  data(){
    return{
      listLoading:false,
      tableData:[],
      isAdd:true,
      dialogVisible:false,
      dialogLoading:false,
      form:{
        sortNo:'',
        name:''
      },
      rules:{
        name:[{required:true,message:'请输入名称',trigger:'blur'}]
      },
      currentRow:{},
      previousPageUrl:'', // 上一个页面的url
      pages:{
        pageIndex:1,
        pageSize:20,
        dataCount:0
      },
      filters:{
        name:'',
      },
      seeVisible:false
    }
  },
  methods:{
    // 时间格式化
    formatCreateTime: function(row, column) {
      return !row.CreateTime || row.CreateTime == ""
      ? ""
      : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd hh:mm:ss");
    },
    // 选择当前行
    selectCurrentRow(row){
      this.currentRow = row
    },
    // 查询
    handleSearch(){
      this.pages.pageIndex = 1
      this.getData()
    },
    // 新增
    handleAdd(){
      this.isAdd = true
      this.dialogVisible = true
    },
    // 编辑
    handleEdit(){
      var row = this.currentRow
      if(JSON.stringify(row) == '{}'){
        this.$message.warning('请选择要编辑的一行数据')
      }else{
        this.isAdd = false
        this.form = {
          sortNo:row.SortNo,
          name:row.Name
        }
        this.dialogVisible = true
      }
    },
    // 删除
    handleDel(){
      var row = this.currentRow
      if(JSON.stringify(row) == '{}'){
        this.$message.warning('请选择要删除的一行数据')
      }else{
        this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() =>{
          var params = {id:row.ID}
          deleteTeachingVideoCategory(Qs.stringify(params)).then(res => {
            if(res.data.Message){
              this.$message.success(res.data.Message)
              this.getData()
            }else{
              this.$message.error(res.data.Message)
            }
          }).catch(() =>{})
        }).catch(() =>{})
      }
    },
    // 返回
    back(){    
      this.$router.push({path:'/VideoCatelogueManage',query:{id:`${this.$route.query.id}`}}) // url加密
    },
    // 查看
    see(id){
      var params = {id}
      GetTeachingVideoCategoryInfo(params).then(res =>{
        this.seeVisible = true
        if(res.data.Success){
          var data = res.data.Response
          this.form = {
            sortNo:data.SortNo,
            name:data.Name
          }
        }else{
          this.$message.error(res.data.Message)
          this.seeVisible = false
        }
      }).catch(() =>{
        this.seeVisible = false
      })
    },
    // 分页
    handleSizeChange(value){
      this.pages.pageSize = value
      this.getData()
    },
    handleCurrentChange(value){
      window.sessionStorage.setItem('pagination',JSON.stringify(value))
      this.pages.pageIndex = value
      this.getData()
    },
    // 对话框关闭
    handleClose(){
      this.form = {
        sortNo:'',
        name:''
      }
      this.$refs.formRef.resetFields()
    },
    seeHandleClose(){
      this.form = {
        sortNo:'',
        name:''
      }
    },

    // 表单提交
    submit(){
      this.$refs['formRef'].validate((valid) => {
          if(valid){
            if(this.isAdd){
              this.addSubmit()
            }else{
              this.editSubmit()
            }
          }
      })
    },
    addSubmit(){
      var params ={...this.form}
      this.dialogLoading = true
      addTeachingVideoCategory(params).then(res =>{
        this.dialogLoading = false
        this.dialogVisible = false
        if(res.data.Success){
          this.$message.success(res.data.Message)
          this.getData()
        }else{
          this.$message.error(res.data.Message)
        }
      }).catch(() =>{
        this.dialogLoading = false
        this.dialogVisible = false
      })
    },
    editSubmit(){
      var params = {...this.form}
      params.id = this.currentRow.ID
      updateTeachingVideoCategoryInfo(params).then(res =>{
        this.dialogLoading = false
        this.dialogVisible = false
        if(res.data.Success){
          this.$message.success(res.data.Message)
          this.getData()
        }else{
          this.$message.error(res.data.Message)
        }
      }).catch(() =>{
        this.dialogLoading = false
        this.dialogVisible = false
      })
    },
    // 获取列表数据
    getData(){
      var params = {
        name:this.filters.name,
        pageIndex:this.pages.pageIndex,
        pageSize:this.pages.pageSize
      }
      this.listLoading = true
      getTeachingVideoCategoryListPage(params).then(res => {
        this.listLoading = false
        if(res.data.Success){
          this.tableData = res.data.Response.Data
          this.pages.dataCount = res.data.Response.DataCount
        }else{
          this.$message.error(res.data.Message)
        }
      }).catch(() =>{
        this.listLoading = false
      })
    }
  },
  beforeRouteEnter(to,from,next){
    if(from.path.indexOf(to.path) == -1){
        window.sessionStorage.removeItem('pagination')

        // 获取上一个页面的url  -----   该守卫不能访问this，需通过next进行回调
        next(vm =>{
          vm.previousPageUrl = from.path
        })
    }
    
  },
  created(){
    this.pages.pageIndex = Number(window.sessionStorage.getItem('pagination')) || 1
    this.getData()
  }
}
</script>

<style lang="stylus" scoped>

</style>