<template>
    <div class="wrapper">
        <ul class="bg-bubbles">
            <li v-for="n in 10" :key="n+'n'"></li>
            <ol v-for="m in 5"  :key="m+'m'"></ol>
        </ul>
        <div style="height: 15%;"></div>
        <el-form :model="ruleForm2" :rules="rules2" ref="ruleForm2" label-position="left" label-width="0px"
                 class="demo-ruleForm login-container">
            <h3 class="title">系统登录</h3>
            <el-form-item prop="account">
                <el-input type="text" @keyup.enter.native="enterKey" v-model="ruleForm2.account" auto-complete="off" placeholder="账号"></el-input>
            </el-form-item>
            <el-form-item prop="checkPass">
                <el-input v-model="ruleForm2.checkPass" @keyup.enter.native="enterKey" auto-complete="off" show-password placeholder="密码"></el-input>
            </el-form-item>
            <el-checkbox v-model="checked" class="remember">记住密码</el-checkbox>

            <el-form-item style="width:100%;">
                <el-button type="primary" style="width:100%;" @click.native.prevent="handleSubmit2" :loading="logining">
                    {{loginStr}}
                </el-button>

            </el-form-item>
           
        </el-form>
    </div>
</template>

<script>
    import {requestLogin,getUserByToken,getNavigationBar} from '../api/api';
    import { JSEncrypt } from 'jsencrypt'
    import router from '@/router'
    import util from '../../util/date'
    import {resetRouter, filterAsyncRouter} from '@/router/index'

    import Cookies from 'js-cookie'

    export default {
        data() {
            return {
                loginStr: '登录',
                logining: false,
                ruleForm2: {
                    account: '',
                    checkPass: ''
                },
                rules2: {
                    account: [
                        {required: true, message: '请输入账号', trigger: 'blur'},
                    ],
                    checkPass: [
                        {required: true, message: '请输入密码', trigger: 'blur'},
                    ]
                },
                checked: false
            };
        },
        methods:{
            // 回车
            enterKey() {
                if(!this.logining){
                    this.handleSubmit2();
                }
            },
            // 获取 Token
            handleSubmit2(ev) {
                var _this = this;
                this.$refs.ruleForm2.validate((valid) => {
                    if (valid) {
                        _this.logining = true;
                        var loginParams = {account: _this.ruleForm2.account, password: _this.encryptedData(_this.ruleForm2.checkPass)};
                        _this.loginStr = "登录中...";
                        requestLogin(loginParams).then(data => {
                            if (!data.Success) {
                                _this.$message({
                                    // message: data.Message,
                                    message: '用户名或密码错误！',
                                    type: 'error'
                                });
                                _this.logining = false;
                                _this.loginStr = "重新登录";
                                // _this.closeAlert()
                            } else {
                                var token = data.Response.token;
                                _this.$store.commit("saveToken", token);

                                var curTime = new Date();
                                var expiredate = new Date(curTime.setSeconds(curTime.getSeconds() + data.Response.expiresIn));
                                _this.$store.commit("saveTokenExpire", expiredate);

                                window.localStorage.refreshtime = expiredate;
                                window.localStorage.expiresIn = data.Response.expiresIn;

                                // _this.closeAlert()
                                // _this.openAlert("成功获取Token，等待服务器返回用户信息...")
                                _this.loginStr = "成功获取Token，等待服务器返回用户信息...";

                                _this.getUserInfoByToken(token)


                            }
                        })
                        .catch(
                            setTimeout(function(){
                                _this.logining = false,
                                _this.loginStr = "重新登录"
                            },20000)
                        )
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            encryptedData (password) {
                let encrypt = new JSEncrypt()
                let publicKey = "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAlOcpcl2eTDnPNnAkSfmX+AX4mc2DBufWXxwTf6Id/gV1sm3oAaOxfW2H0OqzpmA5fIEBegYzNS3kzK117r+5Afwh8d2WSfcyJa6pgJezRIG8ToYyFa6IfwwtShJEZH1hn9hH/4WJI8lpzQxe8GrwyAy9kkMt9IX+Ud1jd2yLiOQNsDdZDUKZ39YGExCp/ZBDiRy0lSDGOWtCNQKf/Gyj8nrC+mw3cKOJ1e+6XS38nOok7ObFS81rc5peiLN6Yw4RFr4Qtg4nnZECxvUVHgBBxg5d1PrYfpo6vxSAng77lEN/Um6maOo4M2i6OZ88vd55g8O7Da0f3/Mhk1oqHY8pmQIDAQAB"
                encrypt.setPublicKey(publicKey)
                let result = encrypt.encrypt(password)
                return result
            },
            // 获取用户数据
            getUserInfoByToken(token) {
                var _this = this;
                var loginParams = {token: token};
                getUserByToken(loginParams).then(data => {

                    if (!data.Success) {
                        _this.$message({
                            message: data.Message,
                            type: 'error'
                        });
                    } else {
                        // _this.closeAlert()
                        // _this.openAlert("接收到用户数据，开始初始化路由树...")
                        if(data.Response.IsInitialization){
                            router.replace({
                                path: "/callBackLogin",
                                query: {redirect: router.currentRoute.fullPath,isRemove:"true",}
                            });
                        }else{
                            // 记住密码
                            if(this.checked){
                                var strMi = this.ruleForm2.account+'|'+this.ruleForm2.checkPass
                                var MiStr = util.compileStr(strMi)
                                Cookies.set('ASP.pass',MiStr,{expires: 7,});
                            }else{
                                Cookies.remove('ASP.pass');
                            }

                            
                            _this.loginStr = "接收到用户数据，开始初始化路由树...";
                            window.localStorage.user = JSON.stringify(data.Response)
                            if (data.Response.ID > 0) {
                                _this.GetNavigationBar(data.Response.ID)
                            }
                        }
                    }
                });
            },
            // 获取路由树
            GetNavigationBar(userID) {
                var _this = this;
                var loginParams = {userID: userID, t: new Date()};

                getNavigationBar(loginParams).then(data => {
                    // console.log("data",data)
                    _this.logining = false;


                    if (!data.Success) {
                        _this.$message({
                            message: data.Message,
                            type: 'error'
                        });
                    } else {

                        // _this.closeAlert()

                        let userinfo = JSON.parse(window.localStorage.user ? window.localStorage.user : null);
                        _this.$notify({
                            type: "success",
                            message: `登录成功 \n 欢迎管理员：${userinfo.Name} ！Token 将在 ${window.localStorage.expiresIn / 60} 分钟后过期！`,
                            duration: 6000
                        });


                        window.localStorage.router = (JSON.stringify(data.Response.children));

                        let getRouter = data.Response.children//后台拿到路由
                        getRouter = filterAsyncRouter(getRouter) //过滤路由
                        router.$addRoutes(getRouter) //动态添加路由

                        // console.log("路由",_this.$route.query.redirect)
                        _this.$router.replace(_this.$route.query.redirect ? _this.$route.query.redirect : "/");
                        // window.location.reload()
                    }
                });
            },

        },
        created() {
            var ASP_Pass = Cookies.get("ASP.pass");
            var OpenStr = util.uncompileStr(ASP_Pass)
            if(ASP_Pass){
                this.ruleForm2.account = util.getCaption(OpenStr,0)
                this.ruleForm2.checkPass = util.getCaption(OpenStr,1)
                this.checked = true
            }else{
                this.checked = false
            }
        }
       
    }

</script>

<style>
    .bg {
        margin: 0px;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: url(../assets/loginbck.png) no-repeat top left;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
    }

    .login-container {
        -webkit-border-radius: 5px;
        border-radius: 5px;
        -moz-border-radius: 5px;
        background-clip: padding-box;
        margin: auto;
        width: 350px;
        padding: 35px 35px 15px 35px;
        background: #fff;
        border: 1px solid #eaeaea;
        box-shadow: 0 0 25px #cac6c6;
        z-index: 9999;
        position: relative;
    }

    .login-container .title {
        margin: 0px auto 40px auto;
        text-align: center;
        color: #505458;
    }

    .login-container .remember {
        margin: 0px 0px 25px 0px;
    }
    
    .wrapper {
        background: #50a3a2;
        background: -webkit-linear-gradient(top left, #50a3a2 0%, #53e3a6 100%);
        background: linear-gradient(to bottom right, #127c7b 0, #50a3a2);
        opacity: 0.8;
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .wrapper.form-success .containerLogin h1 {
        -webkit-transform: translateY(85px);
        -ms-transform: translateY(85px);
        transform: translateY(85px);
    }

    .containerLogin {
        max-width: 600px;
        margin: 0 auto;
        padding: 80px 0;
        height: 400px;
        text-align: center;
    }

    .containerLogin h1 {
        font-size: 40px;
        -webkit-transition-duration: 1s;
        transition-duration: 1s;
        -webkit-transition-timing-function: ease-in-put;
        transition-timing-function: ease-in-put;
        font-weight: 200;
    }

    .bg-bubbles {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .bg-bubbles li, .bg-bubbles ol {
        position: absolute;
        list-style: none;
        display: block;
        width: 40px;
        height: 40px;
        background-color: rgba(255, 255, 255, 0.15);
        bottom: -160px;
        -webkit-animation: square 25s infinite;
        animation: square 25s infinite;
        -webkit-transition-timing-function: linear;
        transition-timing-function: linear;
    }

    ol {
        padding: 0 !important;
    }

    .bg-bubbles ol:nth-child(11) {
        left: 10%;
        top: 10%;
        width: 20px;
        height: 20px;
    }

    .bg-bubbles ol:nth-child(12) {
        left: 20%;
        top: 40%;

        width: 60px;
        height: 60px;
    }

    .bg-bubbles ol:nth-child(13) {
        left: 65%;
        top: 30%;

        width: 100px;
        height: 60px;
    }

    .bg-bubbles ol:nth-child(14) {
        left: 70%;
        top: 30%;
        width: 100px;
        height: 150px;
    }

    .bg-bubbles ol:nth-child(15) {
        left: 50%;
        top: 70%;

        width: 40px;
        height: 60px;
    }

    .bg-bubbles li:nth-child(1) {
        left: 10%;
    }

    .bg-bubbles li:nth-child(2) {
        left: 20%;
        width: 80px;
        height: 80px;
        -webkit-animation-delay: 2s;
        animation-delay: 2s;
        -webkit-animation-duration: 17s;
        animation-duration: 17s;
    }

    .bg-bubbles li:nth-child(3) {
        left: 25%;
        -webkit-animation-delay: 4s;
        animation-delay: 4s;
    }

    .bg-bubbles li:nth-child(4) {
        left: 40%;
        width: 60px;
        height: 60px;
        -webkit-animation-duration: 22s;
        animation-duration: 22s;
        background-color: rgba(255, 255, 255, 0.25);
    }

    .bg-bubbles li:nth-child(5) {
        left: 70%;
    }

    .bg-bubbles li:nth-child(6) {
        left: 80%;
        width: 120px;
        height: 120px;
        -webkit-animation-delay: 3s;
        animation-delay: 3s;
        background-color: rgba(255, 255, 255, 0.2);
    }

    .bg-bubbles li:nth-child(7) {
        left: 32%;
        width: 160px;
        height: 160px;
        -webkit-animation-delay: 7s;
        animation-delay: 7s;
    }

    .bg-bubbles li:nth-child(8) {
        left: 55%;
        width: 20px;
        height: 20px;
        -webkit-animation-delay: 15s;
        animation-delay: 15s;
        -webkit-animation-duration: 40s;
        animation-duration: 40s;
    }

    .bg-bubbles li:nth-child(9) {
        left: 25%;
        width: 10px;
        height: 10px;
        -webkit-animation-delay: 2s;
        animation-delay: 2s;
        -webkit-animation-duration: 40s;
        animation-duration: 40s;
        background-color: rgba(255, 255, 255, 0.3);
    }

    .bg-bubbles li:nth-child(10) {
        left: 90%;
        width: 160px;
        height: 160px;
        -webkit-animation-delay: 11s;
        animation-delay: 11s;
    }

    @-webkit-keyframes square {
        0% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }

        100% {
            -webkit-transform: translateY(-700px) rotate(600deg);
            transform: translateY(-700px) rotate(600deg);
        }
    }

    @keyframes square {
        0% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }

        100% {
            -webkit-transform: translateY(-700px) rotate(600deg);
            transform: translateY(-700px) rotate(600deg);
        }
    }

    .content-az {
        padding: 0 !important;
        border: none !important;
    }
</style>
