<template>
    <div>
        <!-- 视频目录管理 -->

        <!-- 顶部 -->
        <el-row>
            <el-col :span='24'>
                <el-form :inline='true'>
                    <el-form-item label='关键字:'>
                        <el-input v-model='filters.name' placeholder='请输入视频名称' clearable @clear='getData' @input='() => (filters.name = filters.name.replace(/\s/g,""))' @keyup.enter.native='getData'></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type='primary' @click='getData'>查询</el-button>
                        <!-- <el-button type='primary' @click='catalogueManage'>目录管理</el-button> -->
                        <el-button type='primary' @click='classifyManage'>分类管理</el-button>
                        <el-button type='primary' @click='handleAdd'>添加视频</el-button>
                        <el-button type='primary' @click='handleEdit'>编辑视频</el-button>
                        <!-- <el-button type='danger' @click='handleDel'>删除视频</el-button> 24-5-30删除按钮会删除保利威视频，暂时隐藏删除按钮 -->
                        <el-button type='primary' @click='back'>返回</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <!-- 添加/编辑视频 -->
        <el-dialog
        :title="isAdd?'新增':'编辑'"
        :visible.sync="dialogVisible"
        width="50%"
        :close-on-click-modal='false'
        @close='handleClose'>
        <div>
            <el-form :model="form" :rules="rules" ref="formRef" label-width="120px">
                <el-form-item label="所属教材：" prop="">
                    <el-select v-model="form.teachingMaterialName" placeholder="请选择" :disabled='true'>
                        <el-option
                        v-for="item in options1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="排序序号：" prop="sortNo">
                    <el-input v-model.trim="form.sortNo"></el-input>
                </el-form-item>
                <el-form-item label="视频名称：" prop="name">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="所属分类：" prop="categoryID">
                    <el-select v-model="form.categoryID" :disabled="true" placeholder="请选择">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属章节：" prop="pCatalogID" style='content:"*";color:red;'>
                    <el-select v-model="form.pCatalogID" placeholder="请选择">
                        <el-option
                        v-for="item in options2"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select> 
                </el-form-item>
                <!-- :on-change="uploadVideo" -->
                <el-form-item label="视频文件：" prop="vid">
                    <input type="file" ref='files' @change='uploadVideo' v-if='isHidden == false'>
                    <el-progress :percentage="uploadProgress" v-if="uploadProgress > 0 && uploadProgress < 100"></el-progress>
                    <el-button size='small' type='primary' v-if='isAdd && form.vid' @click='openVideo(vid)'>点击播放</el-button>
                    <div id='player'></div>
                    <!-- <el-button size='small' v-if='isHidden != false' type='danger' @click='deletVideo(vid)'>删除视频</el-button> 24-5-30删除按钮会删除保利威视频，暂时隐藏删除按钮-->
                </el-form-item>
                <el-form-item label="版本类型：" prop="editionType">
                    <el-radio-group v-model="form.editionType">
                        <el-radio :label="1">标准版</el-radio>
                        <el-radio :label="2">精讲版</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="submint" :loading='dialogLoading'>确 定</el-button>
        </span>
        </el-dialog>
        <!-- 列表 -->
        <el-table
        border
        :data="tableData"
        highlight-current-row
        @current-change='selectCurrentRow'
        style="width: 95%">
            <el-table-column  type="index"  width="55" label='序号'></el-table-column>
            <!-- <el-table-column  prop="SortNo" label="序号" width="60" align='center'></el-table-column> -->
            <el-table-column  prop="Name" label="视频名称" width="" align='center' show-overflow-tooltip></el-table-column>
            <el-table-column  prop="TeachingMaterialName" label="教材名称" width="" align='center' show-overflow-tooltip></el-table-column>
            <!-- <el-table-column  prop="CatalogName" label="目录名称" width="" align='center' show-overflow-tooltip></el-table-column> -->
            <el-table-column  prop="PCatalogName" label="所属章节" width="" align='center' show-overflow-tooltip></el-table-column>
            <el-table-column  prop="EditionType" label="版本类型" width="" align='center'>
                <template slot-scope='scope'>
                    <el-tag :type='scope.row.EditionType == 1 ? "primary" : "warning"'>{{scope.row.EditionType == 1 ? '标准版' : '精讲版'}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column  prop="CreateTime" label="创建时间" width="" align='center' :formatter="formatCreateTime" show-overflow-tooltip></el-table-column>
            <el-table-column  prop="CreateUserID" label="创建人" width="" align='center' show-overflow-tooltip></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pages.pageIndex"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pages.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pages.dataCount">
        </el-pagination>
    </div>
</template>

<script>
import PlvVideoUpload from '@polyv/vod-upload-js-sdk';
import Qs from 'qs';
import util from '../../../util/date'
import {
    getTeachingVideoListPage,
    addTeachingVideoInfo,
    updateTeachingVideoInfo,
    deleteTeachingVideo,
    getTeachingVideoCatalog,
    getTeachingVideoCategory,
    getTeachingMaterialTree,
    getPolyvUserData,
    getPlaysafeToken,
    getTeachingVideoCategoryListPage,
    } from '@/api/api'
export default {
    data(){
        return {

            buttonList:[],
            filters:{
                name:''
            },
            id:'',
            isAdd:true, // 新增或者编辑
            dialogVisible:false, //对话框显示与隐藏
            dialogLoading:false,
            // 视频表单数据
            form:{
                sortNo:'',
                name:'',
                // catalogID:'', // 目录id
                pCatalogID:'', // 父级目录id
                categoryID:'', // 分类id
                videoUrl:'',
                vid:'',
                auditStatus:0,
                videoStatus:0,
                videoType:1,
                editionType:1,
                teachingMaterialName:'', //
                totalVideoDuration:0 // 视频总时长
            },
            rules:{
                sortNo:[{required:true,message:'请输入排序序号',trigger:'blur'}],
                name:[{required:true,message:'请输入视频名称',trigger:'blur'}],
                pCatalogID:[{required:true,message:'请选择所属章节',trigger:'change'}],
                categoryID:[{required:true,message:'请输入分类',trigger:'blur'}],
                vid:[{required:true,message:'请上传视频',trigger:'change'}]
            },
            pages:{
                pageIndex:1,
                pageSize:20,
                dataCount:0
            },
            teachingMaterialID:'', // 所属教材id
            
            fileList:[],
            vid:'', // 视频上传成功，返回的vid
            timeStamp:'', // 视频时长
            count:0, // 视频上传，防止出现多个上传成功提示
            uploadTxt:'点击上传', // 上传文本
            uploadLoading:false, // 上传加载
            vodPlayerJs: 'https://player.polyv.net/script/player.js',//保利威视频播放js
            player:'',
            isHidden:false,
            // 所属分类
            options:[],
            // 所属教材
            options1:[],
            // 所属章节
            options2:[],
            // // 一级目录
            // cate1:[],
            // // 二级目录
            // cate2:[],
            tableData:[],
            currentRow:{},
            uploadProgress: 0, //上传视频进度
        }
    },
    computed:{
        headers(){
            return {
                "Authorization": 'Bearer ' + localStorage.getItem('Token')
            }
        },
    },
    methods:{
        // 时间格式化
        formatCreateTime: function(row, column) {
            return !row.CreateTime || row.CreateTime == ""
            ? ""
            : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd hh:mm:ss");
        },
        // 获取列表数据
        getData(){
            var params = {
                teachingMaterialID:Number(this.$route.query.id),
                categoryId:'',
                pCatalogId:'',
                catalogId:'',
                name:this.filters.name,
                isEnable:'',
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
            }
            this.listLoading = true
            getTeachingVideoListPage(params).then(res =>{
                this.listLoading = false
                if(res.data.Success){
                    this.tableData = res.data.Response.Data
                    this.pages.dataCount = res.data.Response.DataCount
                }else{
                    this.$message.error(res.data.Message)
                }
            }).catch(() => {this.listLoading = false})

        },
        // 得到章节数据
        getPCatalogData(){
            var params = {
                parentId:this.teachingMaterialID,
                isEnable:true
            }
            getTeachingMaterialTree(params).then(res => {
                if(res.data.Success){
                    var data = res.data.Response
                    this.options2 = data.map(item => ({
                        label : item.Name,
                        value : item.ID
                    }))
                }
            })
        },
        // 分类管理
        classifyManage(){
            this.$router.push({path:'/ClassifyManage',query:{id:`${this.$route.query.id}`}}) // 加密
        },
        // 新增
        handleAdd(){
            this.form = {
                sortNo:'',
                name:'',
                // catalogID:'', // 目录id
                pCatalogID:'', // 父级目录id
                categoryID:Number(this.$route.query.dataType), // 分类id
                videoUrl:'',
                vid:'',
                auditStatus:0,
                videoStatus:0,
                videoType:1,
                editionType:1,
                totalVideoDuration:0
                // teachingMaterialName:'', //
            }
            this.getClassifyData() // 得到分类数据
            this.getTeachingMateName() // 得到教材数据
            
            this.count = 0
            this.uploadTxt = '点击上传'
            this.isAdd = true
            this.isHidden == false
            this.dialogVisible = true
        },
        // 获取表格当前行
        selectCurrentRow(row){
            this.currentRow = row
        },
        // 编辑
        handleEdit(){
            var row = this.currentRow
            if(JSON.stringify(row) == '{}'){
                this.$message.warning('请选择要编辑的一行数据')
            }else{
                this.isAdd = false
                this.getClassifyData()
                this.form = {
                    sortNo:row.SortNo,
                    name:row.Name,
                    // catalogID:Number(row.CatalogID), // 目录id
                    pCatalogID:row.PCatalogID, // 父级目录id
                    categoryID:Number(row.CategoryID), // 分类id
                    videoUrl:row.VideoUrl,
                    vid:row.Vid,
                    auditStatus:row.AuditStatus,
                    videoStatus:row.VideoStatus,
                    videoType:row.VideoType,
                    editionType:row.EditionType,
                    teachingMaterialName:row.TeachingMaterialName, 
                    totalVideoDuration:row.TotalVideoDuration
                }
                this.isHidden = true
                this.vid = row.Vid
                this.openVideo(this.vid)
                // setTimeout(() => {
                    this.dialogVisible = true
                // },1500)
                
            }
        },
        // 视频表单提交
        submint(){           
                this.$refs.formRef.validate((valid) => {
                    if(valid){
                        if(this.isAdd){
                            this.dialogLoading = true
                            var params = {...this.form}
                            params.teachingMaterialID = this.$route.query.id
                            this.addFormSubmit(params)                      
                        }else{
                            this.dialogLoading = true
                            var params = {...this.form}
                            params.id = this.currentRow.ID
                            params.teachingMaterialID = this.$route.query.id
                            if(params.catalogID == '' || params.pCatalogID == ''){
                                this.$message.warning('请将表单填写完整')
                                this.dialogLoading = false
                                return
                            }else{
                                this.editFormSubmit(params)
                            }
                        }
                    }
                })           
        },
        addFormSubmit(params){
            addTeachingVideoInfo(params).then(res => {
                this.dialogLoading = false
                this.dialogVisible = false
                if(res.data.Success){
                    this.$message.success(res.data.Message)
                    this.getData()
                }else{
                    this.$message.warning(res.data.Message)
                }
            }).catch(() => {
                this.dialogLoading = false
                this.dialogVisible = false
            })
        },
        editFormSubmit(params){
            updateTeachingVideoInfo(params).then(res => {
                this.dialogLoading = false
                this.dialogVisible = false
                if(res.data.Success){
                    this.$message.success(res.data.Message)
                    this.getData()
                }else{
                    this.$message.error(res.data.Message)
                }
            }).catch(() =>{
                this.dialogLoading = false
                this.dialogVisible = false
            })
        },
        // 删除
        handleDel(){
            var row = this.currentRow
            if(JSON.stringify(row) == '{}'){
                this.$message.warning('请选择要删除的一行数据')
            }else{
                this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    var params = {id:row.ID}
                    deleteTeachingVideo(Qs.stringify(params)).then(res => {
                        if(res.data.Success){
                            this.$message.success(res.data.Message)
                            this.getData()
                            this.vid = ''
                            this.form.vid = ''
                            if (this.player) {
                                this.player.destroy()
                            }  
                        }else{
                            this.$message.error(res.data.Message)
                        }
                    }).catch(() => {})
                }).catch(() =>{})
            }
        },
        // 返回
        back(){
            this.$router.push({path:'/MasterData/TeachingVideo'})
        },
        // 对话框关闭
        handleClose(){
            this.form = {
                sortNo:'',
                name:'',
                catalogID:'', // 目录id
                pCatalogID:'', // 父级目录id
                categoryID:'', // 分类id
                videoUrl:'',
                vid:'',
                auditStatus:0,
                videoStatus:0,
                videoType:1,
                editionType:1,
                teachingMaterialName:'', //
            }
            
            this.$refs.formRef.resetFields()
            this.vid = ''
            this.count = 0
            this.isHidden = false
            if (this.player) {
                this.player.destroy()
            }           
        },
        // 分页
        handleSizeChange(value){
            this.pages.pageSize = value
            this.getData()
        },
        handleCurrentChange(value){
            this.pages.pageIndex = value
            this.getData()
        },

        // 视频上传相关操作
        uploadVideo(e){
            var file = e.target.files[0]
            var _this = this
            var videoUpload = new PlvVideoUpload({
                region: 'line1', // (可选)上传线路, 默认line1
                events: {
                    Error: (err) => {  // 错误事件回调
                        // console.log(err);
                    },
                    UploadComplete: function(Class){
                        
                    }  // 全部上传任务完成回调
                }
            })
            if (!file) {
                this.$message.error('请选择您要上传的文件')
                return false
            }
            var _this = this
            
            getPolyvUserData().then(res => {
                if(res.data.Success){
                    var data = res.data.Response
                    videoUpload.updateUserData({
                        userid: data.userid, // Polyv云点播账号的ID
                        ptime: data.ptime , // 时间戳
                        sign: data.sign , // 是根据将secretkey和ts按照顺序拼凑起来的字符串进行MD5计算得到的值
                        hash: data.hash , // 是根据将ts和writeToken按照顺序拼凑起来的字符串进行MD5计算得到的值
                    })
                    var fileSetting = {
                        title: '',  // 标题
                        desc: '',  // 描述
                        // cataid:1629683611628,  // 上传分类目录ID
                        cataid:1652359636001,  // 上传分类目录ID
                        // cataid:1630031735946, // 我的
                        tag: '',  // 标签
                        luping: 0,  // 是否开启视频课件优化处理，对于上传录屏类视频清晰度有所优化：0为不开启，1为开启
                        keepsource: 0,  // 是否源文件播放（不对视频进行编码）：0为编码，1为不编码
                        // Filedata:/\.(flv|avi|mp4)$/,
                        // Filedata:'.mp4',
                        // watermark:'https://dss2.bdstatic.com/8_V1bjqh_Q23odCf/pacific/1992720858.png'
                    }
                    _this.uploadTxt = '视频文件上传中，耐心等待'
                    _this.uploadLoading = true
                    _this.uploadProgress = 5
                    videoUpload.addFile(
                        e.target.files[0], // file 为待上传的文件对象
                        {   
                            FileStarted: function(uploadInfo) { // 文件开始上传回调
                                // console.log("文件上传开始: " + uploadInfo.fileData.title);
                            },
                            FileProgress: function(uploadInfo) { // 文件上传过程返回上传进度信息回调
                                console.log("文件上传中: " + (uploadInfo.progress * 100).toFixed(2) + '%');
                                _this.uploadProgress = uploadInfo.progress * 100 > 0 ? uploadInfo.progress * 100 : 5
                            },
                            FileStopped: function(uploadInfo) { // 文件暂停上传回调
                                // console.log("文件上传停止: " + uploadInfo.fileData.title);
                            },
                            FileSucceed: function(uploadInfo) { // 文件上传成功回调  
                                    _this.$message.success('视频文件上传成功')
                                    _this.vid = uploadInfo.fileData.vid
                                    _this.form.vid = uploadInfo.fileData.vid
                                    _this.isHidden = true
                            },
                            FileFailed: function(uploadInfo) { // 文件上传失败回调
                                _this.$message.success("文件上传失败" + uploadInfo.fileData.title)
                            }
                        },
                        fileSetting
                    );
                    videoUpload.startAll();
                }else{return}
            })                     
        },
        // 播放视频
        openVideo(vid){
            if(!vid){
                this.vid = ''
            }else{
                this.vid = vid
                this.loadPlayerScript(this.getVideoUploadToken)
            }           
        },
        // 删除视频
        deletVideo() {
            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.isHidden = false
                this.form.vid = ''
                document.getElementById("player").innerHTML = ""
            }).catch(() =>{})
        },
        loadPlayerScript(callback){
            if(!window.polyvPlayer){
                const myScript = document.createElement('script');
                myScript.setAttribute('src', this.vodPlayerJs);
                myScript.onload = callback;
                document.body.appendChild(myScript);
            }else{callback()}
        },
        getVideoUploadToken(){
            var user = JSON.parse(localStorage.getItem('user'))
            var id = user?user.ID:null
            var params = {
                videoId:this.vid,
                viewerId:id
            }
            getPlaysafeToken(params).then(res => {
                if(res.data.Success){
                    var needToken = res.data.Response.data.data.token
                    var polyvPlayer = window.polyvPlayer;
                    this.player = polyvPlayer({
                        wrap:'#player',
                        width:535,
                        height:410,
                        vid:this.vid,
                        playsafe:needToken,
                        code:'xinhu'
                    })
                }else{
                    this.$message.error(res.Message)
                }
            })
        },
        // // 删除视频
        // delVideo(vid){
        //     this.vid = ''
        //     this.form.vid = ''
        //     if (this.player) {
        //         this.player.destroy()
        //     }  
        // },


        ////// 得到分类数据
        getClassifyData(){
            var params = {}
            getTeachingVideoCategoryListPage(params).then(res => {
                if(res.data.Success){
                    var data = res.data.Response.Data
                    this.options = data.map(item => {
                        var obj = {}
                        obj.label = item.Name
                        obj.value = item.CategoryID
                        return obj
                    })
                }else{return}
            }).catch(() =>{})
        },
        // 得到所属教材名称
        getTeachingMateName(){
            var id = this.$route.query.id
            // 在教材树中查找id对应的教材
            var params = {isEnable:true}
            getTeachingMaterialTree(params).then(res =>{
                if(res.data.Success){
                    var data = res.data.Response
                    this.options1 = data.map(item => {
                        var obj = {}
                        obj.label = item.Name
                        obj.value = item.ID
                        return obj
                    })
                    // data.forEach(item => {
                    //     if(item.ID == this.teachingMaterialID){

                    //     }
                    // })
                    // 可过滤掉undefined等值
                    for(var item of data){
                        if(item.ID == id){
                            var a = item
                        }
                    }
                    this.form.teachingMaterialID = a.ID
                    this.form.teachingMaterialName = a.Name
                }              
            })
        },
    },
    destroyed() {
        if (this.player) {
            this.player.destroy()
        }
    },
    beforeRouteEnter(to,from,next){
        if(from.path.indexOf(to.path) == -1){
            window.sessionStorage.removeItem('pagination')
        }   
        // 获取上一个页面的url  -----   该守卫不能访问this，需通过next进行回调
        next()    
    },
    created(){
        this.getData()

        this.teachingMaterialID = this.$route.query.id
        // this.options2 = this.$route.query.chapter
        this.getPCatalogData() // 得到章节数据
    }
}
</script>

<style lang="stylus" scoped>
    .required::before{
        content: '*';
        color: #f00;
        margin-top: 11px;
        float: left;
        margin-left: 27px;
        margin-right: -33px;
    }
</style>